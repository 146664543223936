import React, { useState, useEffect, } from "react";
import { getDoc, getDocs, doc, setDoc, updateDoc, onSnapshot, collection, addDoc, Timestamp, deleteDoc, query, where, writeBatch } from 'firebase/firestore';
import { useNavigate, useParams, Link, useHistory, useNavigation } from 'react-router-dom';
import { getAuth, signOut, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import { FaUser, FaArrowLeft, FaTv } from 'react-icons/fa';
import { db, app } from "../../firebase";
import { getFunctions, httpsCallable, } from 'firebase/functions';
import { useLocation } from 'react-router-dom';
import moment, { weekdaysMin } from 'moment-timezone';
import { useAdminRole } from "../../App";
import { Menu } from "./AccueilAdmin";
import Logo from '../../assets/logo/logo-horizontale-v2.png';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

/*
export const findCombattant = async (id, categories, genre, org) => {
    let foundCombattant = null;

    for (const cat of categories) {
        for (const gen of genre) {
            const combattantQuery = query(
                collection(db, 'fightData', 'organisations', org, gen, cat),
                where('id', '==', id)
            );

            const combattantSnapshot = await getDocs(combattantQuery);

            if (!combattantSnapshot.empty) {
                foundCombattant = combattantSnapshot.docs[0].data();
                break;
            }
        }
        if (foundCombattant) {
            break;
        }
    }
    return foundCombattant;
};
*/

export const findCombattant = async (id, cat, orga) => {
    let foundCombattant = null;

    if (cat === 'catch') {
        // Si cat est 'catch', récupérez directement les données du combattant de 'fighterData'
        const fighterDoc = doc(db, 'fighterData', id);
        const fighterSnapshot = await getDoc(fighterDoc);

        if (fighterSnapshot.exists()) {
            return fighterSnapshot.data(); // Retournez les données directement si le document existe
        } else {
            console.error(`No document found in 'fighterData' with id: ${id}`);
            return null; // Ou retournez null s'il n'y a pas de document avec cet ID
        }
    }

    const combattantRefDoc = doc(db, 'fightData/organisations', orga, 'fighter', cat, id);
    const combattantRefDocSnapshot = await getDoc(combattantRefDoc);

    if (combattantRefDocSnapshot.exists()) {
        const combattantRef = combattantRefDocSnapshot.data().fighterRef;

        if (combattantRef) {
            const combattantSnapshot = await getDoc(combattantRef);
            if (combattantSnapshot.exists()) {
                foundCombattant = combattantSnapshot.data();
            }
        }
    }

    return foundCombattant;
};

export function Live() {

    const { adminRole, setAdminRole, email, setEmail } = useAdminRole();

    const { t } = useTranslation();

    const [events, setEvents] = useState([]);
    const [eventName, setEventName] = useState("");
    const [eventData, setEventData] = useState([]);
    const [combatId, setCombatId] = useState("");
    const [nom1, setNom1] = useState("");
    const [nom2, setNom2] = useState("");
    const [numberOfRound, setNumberOfRound] = useState("");
    const [org, setOrg] = useState("");
    const years = ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(currentYear.toString());
    const [orgs, setOrgs] = useState([]);
    const cards = eventData.ifEarlyPrelims ? ['main card', 'prelims', 'early prelims'] : ['main card', 'prelims'];
    const [selectedCard, setSelectedCard] = useState('');
    const [time, setTime] = useState('');

    useEffect(() => { // RÉCUPÉRATION ORGA
        const fetchOrgs = async () => {

            const orgsDocRef = doc(db, "fightData", "organisations");

            try {
                const orgsDocSnap = await getDoc(orgsDocRef);

                if (orgsDocSnap.exists()) {
                    const { listOrgasNames } = orgsDocSnap.data(); // Assurez-vous que 'listOrgasNames' est correctement initialisé dans votre document
                    setOrgs(listOrgasNames);
                } else {
                    console.log("Document 'organisations' non trouvé.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du document:", error);
            }
        };

        fetchOrgs();
    }, []);


    const ways = [{ value: 0, label: t("None") }, { value: 1, label: "KO/TKO" }, { value: 2, label: "Submission" }, { value: 3, label: "Decision" },
    { value: 3.1, label: "Unanimous Decision" }, { value: 3.2, label: "Split Décision" }, { value: 3.3, label: "Majority Decision" },
    { value: 4, label: "Draw" }, { value: 4.1, label: "Unanimous Draw" }, { value: 4.2, label: "Split Draw" }, { value: 4.3, label: "Majority Draw" },
    { value: 5, label: "NO CONTEST" }, { value: 6, label: "Doctor Stoppage" }, { value: 7, label: "DISQUALIFICATION" }, { value: 8, label: "Forfeit" },
    { value: 9, label: "Technical draw" }, { value: 10, label: "Technical decision" }];

    const wins = [{ value: 0, label: "Aucun" }, { value: 1, label: `${nom1}` }, { value: 2, label: `${nom2}` }];


    const navigate = useNavigate();





    /////////////////////////

    // Charger tous les events

    useEffect(() => { // RÉCUPÉRATION EVENTS
        const fetchEvents = async () => {
            if (selectedYear && org) {
                const querySnapshot = await getDocs(collection(db, "fightData", "organisations", org, "events", selectedYear));
                const events = [];
                querySnapshot.forEach((document) => {
                    const data = document.data();
                    events.push({
                        id: document.id,               // Document ID
                        displayEventName: data.displayEventName || document.id  // Fallback to ID if displayEventName doesn't exist
                    });
                });
                setEvents(events);
            };
        };
        fetchEvents();
    }, [selectedYear, org]);

    // Charger les données de l'event sélectionné
    useEffect(() => {
        const fetchEventData = async () => {
            if (selectedYear && org && eventName) {
                const docRef = doc(db, "fightData", "organisations", org, "events", selectedYear, eventName);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setEventData(data);
                } else {
                    console.log("No such document!");
                }
            }
        };

        fetchEventData();
    }, [selectedYear, eventName, org]);


    /////////////// ADMIN ////////////////
    useEffect(() => {
        const fetchEventData = async () => {
            if (selectedYear && org && eventName) {
                const adminDocRef = doc(db, "fightData", "organisations", org, "events", selectedYear, eventName, 'admin', email);
                const adminDocSnapshot = await getDoc(adminDocRef);

                if (adminDocSnapshot.exists() && (adminDocSnapshot.data().role === "all" || adminDocSnapshot.data().role === "adminResults"
                    || adminDocSnapshot.data().role === "adminStatsStrike" || adminDocSnapshot.data().role === "adminStatsOthers")) {
                    setAdminRole(adminDocSnapshot.data().role);
                    console.log('adminDocSnapshot.data().role', adminDocSnapshot.data().role)
                }
            }
        };

        fetchEventData();
    }, [selectedYear, eventName, org, email]);


    useEffect(() => { // RÉCUPÉRATION TIME 
        if (selectedCard) {
            if (selectedCard === 'early prelims') {
                setTime(eventData.earlyprelimstime)
            } else if (selectedCard === 'prelims') {
                setTime(eventData.prelimstime)
            } else if (selectedCard === 'main card') {
                setTime(eventData.maincardtime)
            }
        }
    }, [selectedCard]);


    const [combats, setCombats] = useState([]);
    const [combatData, setCombatData] = useState([]);
    const [combattant1Data, setCombattant1Data] = useState([]);
    const [combattant2Data, setCombattant2Data] = useState([]);
    const [selectedCombat, setSelectedCombat] = useState('');

    const [formulaireVisible, setFormulaireVisible] = useState(0); // 1 = RÉSULTAT, 2 = STATS

    const [isStopTime, setIsStopTime] = useState(false);
    const [showDecision, setShowDecision] = useState(false);
    const [showEgalite, setShowEgalite] = useState(false);

    const [stateNumber, setStateNumber] = useState(1);
    const [stateRound, setStateRound] = useState(0);

    const [endTimeMinute, setEndTimeMinute] = useState('');
    const [endTimeSeconde, setEndTimeSeconde] = useState('');

    // BOUTON IS PRESS
    const [isPressWin, setIsPressWin] = useState(0);
    const [isPressWay, setIsPressWay] = useState(0);

    ///////////////////////////////////// STATISTIQUES
    const [strikeSign1, setStrikeSign1] = useState(0);
    const [strikeSign2, setStrikeSign2] = useState(0);

    const [strikeNormal1, setStrikeNormal1] = useState(0);
    const [strikeNormal2, setStrikeNormal2] = useState(0);

    const [knockdowns1, setKnockdowns1] = useState(0);
    const [knockdowns2, setKnockdowns2] = useState(0);

    const [takedowns1, setTakedowns1] = useState(0);
    const [takedowns2, setTakedowns2] = useState(0);

    const [subTry1, setSubTry1] = useState(0);
    const [subTry2, setSubTry2] = useState(0);

    //CHRONOMÈTRE TEMPS DE CONTROLE
    const [isRunningEndTime1, setIsRunningEndTime1] = useState(false);
    const [elapsedTimeEndTime1, setElapsedTimeEndTime1] = useState(0);

    const [isRunningControl1, setIsRunningControl1] = useState(false);
    const [elapsedTimeControl1, setElapsedTimeControl1] = useState(0);

    const [isRunningControl2, setIsRunningControl2] = useState(false);
    const [elapsedTimeControl2, setElapsedTimeControl2] = useState(0);

    useEffect(() => {
        const interval1 = isRunningControl1 ? setInterval(() => setElapsedTimeControl1(prev => prev + 1), 1000) : null;
        const interval2 = isRunningControl2 ? setInterval(() => setElapsedTimeControl2(prev => prev + 1), 1000) : null;
        const intervalEndTime = isRunningEndTime1 ? setInterval(() => setElapsedTimeEndTime1(prev => prev + 1), 1000) : null;

        return () => {
            if (interval1) clearInterval(interval1);
            if (interval2) clearInterval(interval2);
            if (intervalEndTime) clearInterval(intervalEndTime);
        };
    }, [isRunningControl1, isRunningControl2, isRunningEndTime1]);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };



    /////////////////RÉCUPÉRATION DES COMBATS
    useEffect(() => {
        const fetchCombats = async () => {
            if (selectedYear && org && eventName && selectedCard) {
                const combatsCollection = collection(db, "fightData", "organisations", org, "events", selectedYear, eventName, 'card');
                const q = query(combatsCollection, where('card', '==', selectedCard));

                const querySnapshot = await getDocs(q);
                const combatsData = [];
                querySnapshot.forEach((document) => {
                    // Inclure le champ 'order' dans les données
                    combatsData.push({ id: document.id, order: document.data().order });
                });

                // Séparer les éléments en numériques et non-numériques
                const numericCombats = combatsData.filter(combat => isNumeric(combat.order));
                const nonNumericCombats = combatsData.filter(combat => !isNumeric(combat.order));

                // Trier les éléments numériques en ordre décroissant
                numericCombats.sort((a, b) => {
                    const orderA = parseInt(a.order, 10);
                    const orderB = parseInt(b.order, 10);
                    return orderA - orderB;
                });

                // Trier les éléments non-numériques en ordre alphabétique décroissant
                nonNumericCombats.sort((a, b) => {
                    if (a.order > b.order) {
                        return -1;
                    }
                    if (a.order < b.order) {
                        return 1;
                    }
                    return 0;
                });

                // Combiner les deux groupes
                const sortedCombats = [...numericCombats, ...nonNumericCombats];

                // Extraire les IDs des combats pour les utiliser dans le menu déroulant
                const combats = sortedCombats.map(combat => combat.id);
                setCombats(combats);
            }
        };
        fetchCombats();

    }, [selectedYear, org, eventName, selectedCard]);

    function isNumeric(value) {
        return /^-?\d+$/.test(value);
    }

    // Charger les données du combat sélectionné
    useEffect(() => {
        const fetchCombatData = async () => {
            if (selectedYear && org && eventName && selectedCombat) {
                const docRef = doc(db, "fightData", "organisations", org, "events", selectedYear, eventName, 'card', selectedCombat);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setCombatData(data);
                    setIsPressWin(0);
                    setIsPressWay(0);
                    setIsStopTime(false);
                    setShowDecision(false);
                    setShowEgalite(false);
                    setEndTimeMinute('');
                    setEndTimeSeconde('');
                    setStateNumber(1);
                    setStateRound(0);

                    setElapsedTimeEndTime1(0);
                } else {
                    console.log("No such document!");
                }
            }
        };

        fetchCombatData();
    }, [selectedYear, eventName, org, selectedCombat]);

    useEffect(() => {
        let unsubscribe;

        const fetchCombatDataRealTime = () => {
            if (selectedYear && org && eventName && selectedCombat) {
                const docRef = doc(db, "fightData", "organisations", org, "events", selectedYear, eventName, 'card', selectedCombat);

                unsubscribe = onSnapshot(docRef, (docSnap) => {
                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        setCombatData(data);
                    } else {
                        console.log("No such document!");
                    }
                });
            }
        };

        fetchCombatDataRealTime();

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [selectedYear, eventName, org, selectedCombat]);

    const [dataReady, setDataReady] = useState(false);

    const categories = ['1heavy', '2lightheavy', '3middle', '4welter', '5light', '6feather', '7bantam', '8fly', '9straw'];
    const genre = ['men', 'women'];

    useEffect(() => { // RÉCUPÉRER LES DONNÉES DES COMBATTANTS
        const fetchCombattantsData = async () => {


            const [combattant1, combattant2] = await Promise.all([
                findCombattant(combatData.nom1, combatData.division, combatData.orga),
                findCombattant(combatData.nom2, combatData.division, combatData.orga),
            ]);


            setCombattant1Data(combattant1)
            setCombattant2Data(combattant2)
            setDataReady(true);
        };

        if (org && combatData) {
            fetchCombattantsData();
        }
    }, [combatData]);


    //////////// ÉTAT DU COMBAT
    const state = async (state) => {

        try {
            const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, 'card', selectedCombat);
            await updateDoc(articleRef, {
                state: state,
                stopTime: 0,
            });

        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    const stopTime = async (stopTime) => {

        try {
            const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, 'card', selectedCombat);
            await updateDoc(articleRef, {
                stopTime: stopTime,
            });
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    //////////// QUI GAGNE
    let delayedUpdateTimeout = null;

    const handleWin = async (winner) => {
        // Mise à jour immédiate de 'win'
        try {
            const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, 'card', selectedCombat);
            await updateDoc(articleRef, {
                win: winner,
            });
        } catch (error) {
            console.error("Error updating document: ", error);
        }

        // Annuler toute mise à jour retardée précédente
        if (delayedUpdateTimeout) {
            clearTimeout(delayedUpdateTimeout);
        }

        // Programmer la mise à jour retardée
        delayedUpdateTimeout = setTimeout(() => {
            performDelayedUpdate(winner);
        }, 30000);
    };

    const performDelayedUpdate = async (winner) => {
        try {
            const documentRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, 'card', selectedCombat);
            const docSnap = await getDoc(documentRef);

            if (docSnap.exists()) {
                const updatedData = docSnap.data();

                if (updatedData.win === winner) {
                    switch (winner) {
                        case 1:
                            // Mettre à jour le document avec les valeurs appropriées
                            await updateDoc(documentRef, {
                                winner: updatedData.nom1,
                                loser: updatedData.nom2
                            });
                            break;

                        case 2:
                            // Mettre à jour le document avec les valeurs appropriées
                            await updateDoc(documentRef, {
                                winner: updatedData.nom2,
                                loser: updatedData.nom1
                            });
                            break;

                        case 3:
                            // Mettre à jour le document avec les valeurs appropriées
                            await updateDoc(documentRef, {
                                draw1: updatedData.nom1,
                                draw2: updatedData.nom2
                            });
                            break;

                        case 4:
                            // Mettre à jour le document avec les valeurs appropriées
                            await updateDoc(documentRef, {
                                NC1: updatedData.nom1,
                                NC2: updatedData.nom2
                            });
                            break;
                    }
                }
            }
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };

    //////////// VICTORY WAY
    const handleVictoryWay = async (way) => {

        try {
            const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, 'card', selectedCombat);
            await updateDoc(articleRef, {
                way: way,
            });

        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    //////////// ROUND
    const handleRound = async (round) => {

        try {
            const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, 'card', selectedCombat);
            await updateDoc(articleRef, {
                round: round,
            });

        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    const handleEndTime = async (e) => {

        if (e) e.preventDefault();
        try {
            const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, 'card', selectedCombat);
            await updateDoc(articleRef, {
                endTime: `${endTimeMinute}:${endTimeSeconde}`,
            });



        } catch (error) {
            console.error("Error adding document: ", error);
        }

    };

    const handleEndTimeDecision = async (newEndTimeMinute, newEndTimeSeconde) => {
        try {
            const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, 'card', selectedCombat);
            await updateDoc(articleRef, {
                endTime: `${newEndTimeMinute}:${newEndTimeSeconde}`,
            });
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };

    ///////////// ENVOYER STATS
    const handleSubmitStatsStrikes = async () => {
        try {
            const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, 'card', selectedCombat, "stats", "statsDoc");
            await updateDoc(articleRef, {

                fighter1_signStrikes: strikeSign1,
                fighter1_totalStrikes: strikeNormal1,
                fighter2_signStrikes: strikeSign2,
                fighter2_totalStrikes: strikeNormal2,

            });

            alert("stats des frappes soumis avec succès !");
        } catch (error) {
            console.error("Error adding document: ", error);
        }

    };

    const handleSubmitStatsOthers = async () => {

        try {
            const articleRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, 'card', selectedCombat, "stats", "statsDoc");
            await updateDoc(articleRef, {
                fighter1_controlTime: formatTime(elapsedTimeControl1),
                fighter1_knockdowns: knockdowns1,
                fighter1_submissionTry: subTry1,
                fighter1_takedowns: takedowns1,
                fighter2_controlTime: formatTime(elapsedTimeControl2),
                fighter2_knockdowns: knockdowns2,
                fighter2_submissionTry: subTry2,
                fighter2_takedowns: takedowns2,


            });

            alert("stats soumis avec succès !");
        } catch (error) {
            console.error("Error adding document: ", error);
        }

    };

    ////////////////
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    const auth = getAuth();

    const handleLogout = () => { // DÉCONNEXION
        signOut(auth).then(() => {
            navigate("/login");
        }).catch((error) => {
            console.error("Error logging out: ", error);
        });
    };

    /////// LIVE DISPLAY
    const [textWidth, setTextWidth] = useState(0);

    useEffect(() => {
        const updateWidth = () => {
            const element = document.getElementById('roundText');
            if (element) {
                setTextWidth(element.offsetWidth);
            }
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, [combatData]);

    const wp = (percentage) => window.innerWidth * (percentage / 100);



    /////// STATISTICS API // NE PAS OUBLIER DE REMETTRE TOUT À ZERO LORS DU CHANGEMENT DE FIGHT
    const [loading, setLoading] = useState(false);
    const [statsButtonAvailable, setStatsButtonAvailable] = useState(false);
    const [statsFetched, setStatsFetched] = useState(false);

    const fetchCombatStatsId = async () => {
        setLoading(true);
        try {
            const docRef = doc(db, "fightData", "organisations", org, 'events', selectedYear, eventName, 'card', selectedCombat);
            const docSnapshot = await getDoc(docRef);

            if (docSnapshot.exists()) {
                const document = docSnapshot.data();
                const documentId = docSnapshot.id;
                let additionalRequests = 2;
                let stateHasChangedToThree = false;

                const options = {
                    method: 'GET',
                    url: 'https://v1.mma.api-sports.io/fights/statistics/fighters',
                    params: { id: document.combatStatsId },
                    headers: {
                        'x-rapidapi-host': 'v1.mma.api-sports.io',
                        'x-rapidapi-key': '0a331cc8c8d2c64f64599ca00f9885a7'
                    }
                };

                try {
                    const response = await axios.request(options);
                    const data = response.data;

                    if (data && data.response && data.response.length >= 2) {
                        const fighter1Stats = data.response[1];
                        const totalStrikesSumFighter1 = fighter1Stats.strikes.total.head + fighter1Stats.strikes.total.body + fighter1Stats.strikes.total.legs;
                        const powerStrikesSumFighter1 = fighter1Stats.strikes.power.head + fighter1Stats.strikes.power.body + fighter1Stats.strikes.power.legs;
                        const fighter1_controlTime = fighter1Stats.strikes.control_time;
                        const fighter1_submissionTry = fighter1Stats.strikes.submissions;
                        const fighter1_takedowns = fighter1Stats.strikes.takedowns.landed;
                        const fighter1_knockdowns = fighter1Stats.strikes.knockdowns;

                        const fighter1_signHead = fighter1Stats.strikes.power.head;
                        const fighter1_signBody = fighter1Stats.strikes.power.body;
                        const fighter1_signLeg = fighter1Stats.strikes.power.legs;

                        const fighter2Stats = data.response[0];
                        const totalStrikesSumFighter2 = fighter2Stats.strikes.total.head + fighter2Stats.strikes.total.body + fighter2Stats.strikes.total.legs;
                        const powerStrikesSumFighter2 = fighter2Stats.strikes.power.head + fighter2Stats.strikes.power.body + fighter2Stats.strikes.power.legs;
                        const fighter2_controlTime = fighter2Stats.strikes.control_time;
                        const fighter2_submissionTry = fighter2Stats.strikes.submissions;
                        const fighter2_takedowns = fighter2Stats.strikes.takedowns.landed;
                        const fighter2_knockdowns = fighter2Stats.strikes.knockdowns;
                        const fighter2_signHead = fighter2Stats.strikes.power.head;
                        const fighter2_signBody = fighter2Stats.strikes.power.body;
                        const fighter2_signLeg = fighter2Stats.strikes.power.legs;

                        console.log(documentId);
                        console.log(`Fighter 1 (ID: ${fighter1Stats.fighter.id}) - Total Strikes: ${totalStrikesSumFighter1}, Power Strikes: ${powerStrikesSumFighter1}`);
                        console.log(`Fighter 2 (ID: ${fighter2Stats.fighter.id}) - Total Strikes: ${totalStrikesSumFighter2}, Power Strikes: ${powerStrikesSumFighter2}`);

                        setStatsFetched(true);
                        // setIsRunningStatsTime(false);
                        //  setElapsedTimeStatsTime(12);

                        const statsRef = doc(db, 'fightData', 'organisations', org, 'events', selectedYear, eventName, 'card', documentId, 'stats', 'statsDoc');

                        await updateDoc(statsRef, {
                            fighter1_signStrikes: powerStrikesSumFighter1,
                            fighter1_totalStrikes: totalStrikesSumFighter1,
                            fighter1_controlTime,
                            fighter1_submissionTry,
                            fighter1_takedowns,
                            fighter1_knockdowns,
                            fighter1_signHead,
                            fighter1_signBody,
                            fighter1_signLeg,
                            fighter2_signStrikes: powerStrikesSumFighter2,
                            fighter2_totalStrikes: totalStrikesSumFighter2,
                            fighter2_controlTime,
                            fighter2_submissionTry,
                            fighter2_takedowns,
                            fighter2_knockdowns,
                            fighter2_signHead,
                            fighter2_signBody,
                            fighter2_signLeg,
                        });
                    } else {
                        console.log("Données insuffisantes pour ", documentId);
                        //  setStatsButtonAvailable(false)

                        // setIsRunningStatsTime(true);
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération des statistiques du combat pour ", documentId, ": ", error);
                }
            } else {
                console.error("Aucun document trouvé pour ", selectedCombat);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des documents : ", error);
        } finally {
            setLoading(false);
        }
    };

    // TIMER STATS
    const [isRunningStatsTime, setIsRunningStatsTime] = useState(false);
    const [elapsedTimeStatsTime, setElapsedTimeStatsTime] = useState(12); // Initialiser à 60 secondes

    useEffect(() => {
        const intervalStatsTime = isRunningStatsTime ? setInterval(() => {
            setElapsedTimeStatsTime(prev => {
                if (prev <= 0) {
                    clearInterval(intervalStatsTime);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000) : null;

        return () => {
            if (intervalStatsTime) clearInterval(intervalStatsTime);
        };
    }, [isRunningStatsTime]);

    useEffect(() => {
        if (elapsedTimeStatsTime === 0) {
            // Logique à exécuter lorsque le temps atteint zéro
            setStatsButtonAvailable(true);
            setIsRunningStatsTime(false);
        }
    }, [elapsedTimeStatsTime]);

    useEffect(() => {
        setLoading(false);
        setStatsButtonAvailable(false);
        setStatsFetched(false);


    }, [combatData.id]);

    /*
    {!statsFetched ?
        <div style={{
            flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px', backgroundColor: 'black'
        }}>
            <h1 style={{ color: 'white' }}>{formatTime(elapsedTimeStatsTime)}</h1>
        </div> : null}
*/

    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>

            <div style={{
                position: 'absolute', top: '0', height: '60px', width: '100%', display: 'flex',
                justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#ff3030', padding: '0 20px'
            }}>
                <div style={{
                    display: 'flex', alignItems: 'center'
                }}>
                    <img src={Logo} alt="Logo" style={{ height: '250px' }} />
                    <h2 style={{ margin: 0, color: 'white', fontSize: '1.2em' }}>LIVE</h2>
                </div>

                <div style={{
                    display: 'flex', alignItems: 'center'
                }}>
                    <button onClick={toggleMenu} style={{
                        padding: '8px 16px', border: 'none', backgroundColor: 'white', color: '#ff3030',
                        borderRadius: '25px', cursor: 'pointer', outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder', marginRight: '10px'
                    }}>
                        {isMenuOpen ? t('Close the menu') : t('Open the menu')}
                    </button>

                    <button onClick={handleLogout} style={{
                        background: 'none', border: '1px solid white', color: 'white', padding: '8px 16px',
                        borderRadius: '25px', cursor: 'pointer', fontWeight: 'bolder'
                    }}>
                        Logout
                    </button>
                </div>
            </div>

            {isMenuOpen && (
                <Menu />
            )}

            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', fontFamily: "'Arial', sans-serif", justifyContent: 'center',
                borderRadius: '10px', width: '100%', margin: '50px auto',
            }}>

                <h2 style={{ color: '#ff3030', marginBottom: '20px' }}>LIVE RESULTS</h2>


                <div style={{ backgroundColor: '#ff3030', padding: '20px', borderRadius: 10, width: '400px', }}>

                    <div style={{ // Organisation
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>Organization :</label>
                        <select
                            value={org}
                            onChange={(e) => setOrg(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select an organization")}</option>
                            {orgs.map((org, index) => (
                                <option key={index} value={org}>
                                    {org}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // YEARS
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>{t("Year")} :</label>
                        <select
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a year")}</option>
                            {years.map((years, index) => (
                                <option key={index} value={years}>
                                    {years}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // EVENT
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>Event :</label>
                        <select
                            value={eventName}
                            onChange={(e) => setEventName(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select an event")}</option>
                            {events.map((event, index) => (
                                    <option key={index} value={event.id}>
                                        {event.displayEventName}
                                    </option>
                                ))}
                        </select>
                    </div>

                    <div style={{ // CARD
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>Card :</label>
                        <select
                            value={selectedCard}
                            onChange={(e) => {
                                setSelectedCard(e.target.value);

                            }}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a card")}</option>
                            {cards.map((cards, index) => (
                                <option key={index} value={cards}>
                                    {cards}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // Combat
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>Fight :</label>
                        <select
                            value={selectedCombat}
                            onChange={(e) => {
                                setSelectedCombat(e.target.value);

                            }}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a fight")}</option>
                            {combats.map((combats, index) => (
                                <option key={index} value={combats}>
                                    {combats}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', }}>
                        <button // RÉSULTAT
                            onClick={() => setFormulaireVisible(1)}
                            style={{
                                padding: '10px 20px', border: 'none', backgroundColor: 'white',
                                color: formulaireVisible === 1 ? '#ff3030' : 'black', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                height: 50, width: '45%', fontSize: 15,
                            }}>
                            RESULTS
                        </button>

                        <button // RÉSULTAT
                            onClick={() => setFormulaireVisible(2)}
                            style={{
                                padding: '10px 20px', border: 'none', backgroundColor: 'white',
                                color: formulaireVisible === 2 ? '#ff3030' : 'black', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                height: 50, width: '45%', fontSize: 15, marginLeft: 20
                            }}>
                            STATISTICS
                        </button>
                    </div>
                </div>

                {formulaireVisible === 1 ? // && (adminRole === 'all' || adminRole === 'adminResults') --> voir explication écrite dans dossier FightApp

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10 }}>
                        <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%', }}>

                            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: 'black' }}>
                                <h2 style={{ color: 'white' }}>{combatData.id}</h2>
                            </div>

                            {dataReady && org === 'UFC' && combatData?.state === 3 && ( // Fetch Combat Stats
                                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginVertical: 20 }}>
                                    <button
                                        onClick={() => {
                                            fetchCombatStatsId(); // setElapsedTimeStatsTime(12); 
                                        }}
                                        disabled={loading}
                                        type="button"
                                        style={{
                                            padding: '10px 20px', border: 'none', backgroundColor: loading ? 'grey' : '#407cff', width: '30%',
                                            color: 'white', borderRadius: '10px', cursor: 'pointer',
                                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                        }}>
                                        {loading ? 'Loading...' : 'Fetch Combat Stats'}
                                    </button>

                                    <span style={{ color: statsFetched ? 'green' : 'red', marginLeft: '2%', marginRight: '2%' }}>
                                        {statsFetched ? '✅' : '❌'}
                                    </span>



                                </div>)}

                            {dataReady && (
                                <div style={{
                                    flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: "#161616",
                                }}>
                                    {combatData.state === 1 &&
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <p style={{ paddingLeft: '1%', fontSize: wp(2), fontWeight: '700', alignSelf: 'center', color: 'white' }}>{t('Imminent start')}</p>
                                        </div>}
                                    {combatData.state === 1.5 && <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <p style={{ paddingLeft: '1%', fontSize: wp(2), fontWeight: '700', alignSelf: 'center', color: 'white' }}>{t('Entrance of the fighters')}</p>
                                    </div>}
                                    {combatData.state === 2 && (
                                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '1%', }}>
                                            {combatData.round === 1 && (
                                                <p style={{ fontSize: wp(2), fontWeight: '700', alignSelf: 'center', color: (combatData.champ === 1 || combatData.champ === 2) ? '#dcb253' : '#1fe05a' }}
                                                    id="roundText">
                                                    Round 1/{combatData.numberOfRound} {t('in progress')}
                                                </p>
                                            )}
                                            {combatData.round === 1.5 && (
                                                <p style={{ fontSize: wp(2), fontWeight: '700', alignSelf: 'center', color: (combatData.champ === 1 || combatData.champ === 2) ? '#dcb253' : '#1fe05a' }}
                                                    id="roundText">
                                                    {t('End of')} Round 1/{combatData.numberOfRound}
                                                </p>
                                            )}
                                            {combatData.round === 2 && (
                                                <p style={{ fontSize: wp(2), fontWeight: '700', alignSelf: 'center', color: (combatData.champ === 1 || combatData.champ === 2) ? '#dcb253' : '#1fe05a' }}
                                                    id="roundText">
                                                    Round 2/{combatData.numberOfRound} {t('in progress')}
                                                </p>
                                            )}
                                            {combatData.round === 2.5 && (
                                                <p style={{ fontSize: wp(2), fontWeight: '700', alignSelf: 'center', color: (combatData.champ === 1 || combatData.champ === 2) ? '#dcb253' : '#1fe05a' }}
                                                    id="roundText">
                                                    {t('End of')} Round 2/{combatData.numberOfRound}
                                                </p>
                                            )}
                                            {combatData.round === 3 && (
                                                <p style={{ fontSize: wp(2), fontWeight: '700', alignSelf: 'center', color: (combatData.champ === 1 || combatData.champ === 2) ? '#dcb253' : '#1fe05a' }}
                                                    id="roundText">
                                                    Round 3/{combatData.numberOfRound} {t('in progress')}
                                                </p>
                                            )}
                                            {combatData.round === 3.5 && (
                                                <p style={{ fontSize: wp(2), fontWeight: '700', alignSelf: 'center', color: (combatData.champ === 1 || combatData.champ === 2) ? '#dcb253' : '#1fe05a' }}
                                                    id="roundText">
                                                    {t('End of')} Round 3/{combatData.numberOfRound}
                                                </p>
                                            )}
                                            {combatData.round === 4 && (
                                                <p style={{ fontSize: wp(2), fontWeight: '700', alignSelf: 'center', color: (combatData.champ === 1 || combatData.champ === 2) ? '#dcb253' : '#1fe05a' }}
                                                    id="roundText">
                                                    Round 4/{combatData.numberOfRound} {t('in progress')}
                                                </p>
                                            )}
                                            {combatData.round === 4.5 && (
                                                <p style={{ fontSize: wp(2), fontWeight: '700', alignSelf: 'center', color: (combatData.champ === 1 || combatData.champ === 2) ? '#dcb253' : '#1fe05a' }}
                                                    id="roundText">
                                                    {t('End of')} Round 4/{combatData.numberOfRound}
                                                </p>
                                            )}
                                            {combatData.round === 5 && (
                                                <p style={{ fontSize: wp(2), fontWeight: '700', alignSelf: 'center', color: (combatData.champ === 1 || combatData.champ === 2) ? '#dcb253' : '#1fe05a' }}
                                                    id="roundText">
                                                    Round 5/{combatData.numberOfRound} {t('in progress')}
                                                </p>
                                            )}
                                            {combatData.round === 5.5 && (
                                                <p style={{ fontSize: wp(2), fontWeight: '700', alignSelf: 'center', color: (combatData.champ === 1 || combatData.champ === 2) ? '#dcb253' : '#1fe05a' }}
                                                    id="roundText">
                                                    {t('End of')} Round 5/{combatData.numberOfRound}
                                                </p>
                                            )}

                                            {combatData.stopTime && combatData.stopTime === 1 && (
                                                <p style={{ fontSize: wp(2), fontWeight: '700', alignSelf: 'center', color: 'red' }}
                                                    id="roundText">
                                                    TIME OUT
                                                </p>
                                            )}

                                        </div>
                                    )}
                                    {combatData.state === 3 && (
                                        <div style={{

                                            justifyContent: 'center', borderRadius: '0.8%', paddingLeft: '1%',
                                        }}>
                                            <p style={{ fontSize: wp(2), fontWeight: '700', alignSelf: 'center', color: '#1fe05a' }}>{t('over')}</p>
                                        </div>
                                    )}

                                    {combatData.state === 3 && combatData.win === 1 && (
                                        <div style={{

                                            justifyContent: 'center', borderRadius: '0.8%', paddingLeft: '1%',
                                        }}>
                                            <p style={{ fontSize: wp(2), fontWeight: '700', alignSelf: 'center', color: '#1fe05a' }}>Winner: {combattant1Data.lastName}</p>
                                        </div>
                                    )}

                                    {combatData.state === 3 && combatData.win === 2 && (
                                        <div style={{

                                            justifyContent: 'center', borderRadius: '0.8%', paddingLeft: '1%',
                                        }}>
                                            <p style={{ fontSize: wp(2), fontWeight: '700', alignSelf: 'center', color: '#1fe05a' }}>Winner: {combattant2Data.lastName}</p>
                                        </div>
                                    )}

                                    {combatData.state === 3 && (
                                        <p style={{
                                            paddingLeft: '1%', fontSize: wp(2), fontWeight: '700', marginTop: '0.7%', color: (combatData.champ === 1 || combatData.champ === 2) ? '#dcb253' : '#1fe05a'
                                        }}>
                                            {combatData.way === 1 && <span>KO/TKO</span>}
                                            {combatData.way === 2 && <span>{t('SUBMISSION')}</span>}
                                            {combatData.way === 3 && <span>{t('Waiting for decision')}</span>}
                                            {combatData.way === 3.1 && <span>{t('UNANIMOUS DECISION')}</span>}
                                            {combatData.way === 3.2 && <span>{t('SPLIT DECISION')}</span>}
                                            {combatData.way === 3.3 && <span>{t('MAJORITY DECISION')}</span>}
                                            {combatData.way === 4.1 && <span>{t('UNANIMOUS DRAW')}</span>}
                                            {combatData.way === 4.2 && <span>{t('SPLIT DRAW')}</span>}
                                            {combatData.way === 4.3 && <span>{t('MAJORITY DRAW')}</span>}
                                            {combatData.way === 5 && <span>NO CONTEST</span>}
                                            {combatData.way === 6 && <span>{t('DOCTOR STOPPAGE')}</span>}
                                            {combatData.way === 7 && <span>{t('DISQUALIFICATION')}</span>}
                                            {combatData.way === 8 && <span>{t('WITHDREW')}</span>}
                                            {combatData.way === 9 && <span>{t('TECHNICAL DRAW')}</span>}
                                            {combatData.way === 10 && <span>{t('TECHNICAL DECISION')}</span>}
                                            {(combatData.round === 1 || combatData.round === 1.5) && <span> - Round 1/{combatData.numberOfRound}</span>}
                                            {(combatData.round === 2 || combatData.round === 2.5) && <span> - Round 2/{combatData.numberOfRound}</span>}
                                            {(combatData.round === 3 || combatData.round === 3.5) && <span> - Round 3/{combatData.numberOfRound}</span>}
                                            {(combatData.round === 4 || combatData.round === 4.5) && <span> - Round 4/{combatData.numberOfRound}</span>}
                                            {(combatData.round === 5 || combatData.round === 5.5) && <span> - Round 5/{combatData.numberOfRound}</span>}
                                            {combatData.endTime && combatData.endTime !== '0:00' && <span>, {combatData.endTime}</span>}
                                        </p>
                                    )}
                                </div>

                            )}

                            {dataReady && (
                                <div style={{ flexDirection: 'row', display: 'flex', width: '100%', }}>
                                    <div style={{ width: '80%', backgroundColor: '#ebebeb' }}>
                                        <div style={{
                                            width: '100%', marginTop: 10, marginBottom: '10px', flexDirection: 'row', justifyContent: 'space-evenly', display: 'flex', alignItems: 'center'
                                        }}>
                                            <div style={{ // fighter1
                                                flexDirection: 'column', display: 'flex', alignItems: 'center'
                                            }}>
                                                <img src={combattant1Data.photo} alt="Logo" style={{ height: 150, width: 220 }} />
                                                <label style={{ fontSize: 25, fontWeight: 'bold', marginTop: 10, }}>{combattant1Data.lastName}</label>
                                                {isPressWay > 0 &&
                                                    <button onClick={() => { handleWin(1); setIsPressWin(1) }} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: '#0ee802',
                                                        color: 'white', borderRadius: 100, marginTop: '30px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 60, width: 200, fontSize: 20, border: isPressWin === 1 ? '4px solid #0576ff' : 0
                                                    }}>
                                                        WINNER
                                                    </button>
                                                }
                                            </div>

                                            <div style={{
                                                flexDirection: 'column', display: 'flex', alignItems: 'center'
                                            }}>

                                                <button onClick={() => { state(1.5); setStateNumber(1.5) }} style={{
                                                    padding: '10px 20px', border: 'none', backgroundColor: '#0576ff',
                                                    color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                    outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                    height: 60, width: 200, fontSize: 20
                                                }}>
                                                    WALK OUT
                                                </button>


                                                <button onClick={() => {
                                                    state(2); setStateNumber(2); handleRound(1); setStateRound(1);
                                                    setIsRunningEndTime1(!isRunningEndTime1)
                                                }} style={{
                                                    padding: '10px 20px', border: 'none', backgroundColor: '#0ee802',
                                                    color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                    outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                    height: 60, width: 200, fontSize: 20
                                                }}>
                                                    {t("START OF THE FIGHT")}
                                                </button>


                                                <button onClick={() => {
                                                    state(3); setStateNumber(3); setIsRunningEndTime1(!isRunningEndTime1); // setIsRunningStatsTime(!isRunningStatsTime);
                                                }} style={{
                                                    padding: '10px 20px', border: 'none', backgroundColor: 'black',
                                                    color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                    outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                    height: 60, width: 200, fontSize: 20
                                                }}>
                                                    {t("OVER")}
                                                </button>


                                                {isStopTime ?
                                                    <button onClick={() => { stopTime(0); setIsStopTime(false); setIsRunningEndTime1(!isRunningEndTime1) }} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: '#0ee802',
                                                        color: 'white', borderRadius: 100, marginTop: '30px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 60, width: 200, fontSize: 20
                                                    }}>
                                                        TIME ON
                                                    </button>
                                                    : <button onClick={() => { stopTime(1); setIsStopTime(true); setIsRunningEndTime1(!isRunningEndTime1) }} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: 'red',
                                                        color: 'white', borderRadius: 100, marginTop: '30px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 60, width: 200, fontSize: 20
                                                    }}>
                                                        TIME OUT
                                                    </button>}



                                                <div style={{
                                                    flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 40,
                                                }}>

                                                </div>

                                            </div>


                                            <div style={{ // fighter 2
                                                flexDirection: 'column', display: 'flex', alignItems: 'center'
                                            }}>
                                                <img src={combattant2Data.photo} alt="Logo" style={{ height: 150, width: 220 }} />
                                                <label style={{ fontSize: 25, fontWeight: 'bold', marginTop: 10, }}>{combattant2Data.lastName}</label>
                                                {isPressWay > 0 &&
                                                    <button onClick={() => { handleWin(2); setIsPressWin(2) }} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: '#0ee802',
                                                        color: 'white', borderRadius: 100, marginTop: '30px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 60, width: 200, fontSize: 20, border: isPressWin === 2 ? '4px solid #0576ff' : 0
                                                    }}>
                                                        WINNER
                                                    </button>
                                                }
                                            </div>
                                        </div>


                                        <div style={{ // way
                                            flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 10, width: '100%',
                                            justifyContent: 'center', alignItems: 'center'
                                        }}>

                                            <button onClick={() => { handleVictoryWay(1); setIsPressWay(1) }} style={{
                                                padding: '10px 20px', border: 'none', backgroundColor: '#0ee802',
                                                color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                height: 60, width: 200, fontSize: 20, border: isPressWay === 1 ? '4px solid #0576ff' : 0
                                            }}>
                                                KO/TKO
                                            </button>

                                            <button onClick={() => { handleVictoryWay(2); setIsPressWay(2) }} style={{
                                                padding: '10px 20px', border: 'none', backgroundColor: '#0ee802',
                                                color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                height: 60, width: 200, fontSize: 20, marginLeft: 5, border: isPressWay === 2 ? '4px solid #0576ff' : 0
                                            }}>
                                                SUBMISSION
                                            </button>

                                        </div>

                                        <div style={{ // way decision
                                            flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 20, width: '100%',
                                            justifyContent: 'center', alignItems: 'center'
                                        }}>

                                            <button onClick={() => {
                                                handleVictoryWay(3); setShowDecision(true); setIsPressWay(3);
                                                { combatData.amateur ? handleEndTimeDecision("3", "00") : handleEndTimeDecision("5", "00") }
                                            }} style={{
                                                padding: '10px 20px', border: 'none', backgroundColor: '#0ee802',
                                                color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                height: 60, width: 200, fontSize: 20, border: isPressWay === 3 ? '4px solid #0576ff' : 0
                                            }}>
                                                DECISION
                                            </button>

                                            {showDecision ?
                                                <>
                                                    <button onClick={() => { handleVictoryWay(3.1); setIsPressWay(3.1) }} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: '#0ee802',
                                                        color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 50, width: 160, fontSize: 15, marginLeft: 5, border: isPressWay === 3.1 ? '4px solid #0576ff' : 0
                                                    }}>
                                                        UNANIMOUS D
                                                    </button>

                                                    <button onClick={() => { handleVictoryWay(3.2); setIsPressWay(3.2) }} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: '#0ee802',
                                                        color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 50, width: 160, fontSize: 15, marginLeft: 5, border: isPressWay === 3.2 ? '4px solid #0576ff' : 0
                                                    }}>
                                                        SPLIT D
                                                    </button>

                                                    <button onClick={() => { handleVictoryWay(3.3); setIsPressWay(3.3) }} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: '#0ee802',
                                                        color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 50, width: 160, fontSize: 15, marginLeft: 5, border: isPressWay === 3.3 ? '4px solid #0576ff' : 0
                                                    }}>
                                                        MAJORITY D
                                                    </button>
                                                </>
                                                : null}
                                        </div>

                                        <div style={{ // way égalité
                                            flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 10, width: '100%',
                                            justifyContent: 'center', alignItems: 'center'
                                        }}>

                                            {showDecision ?
                                                <>
                                                    <button onClick={() => { handleVictoryWay(4.1); setIsPressWay(4.1); handleWin(3); }} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: '#363636',
                                                        color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 50, width: 160, fontSize: 15, marginLeft: 5, border: isPressWay === 4.1 ? '4px solid #0576ff' : 0
                                                    }}>
                                                        UNANIMOUS DRAW
                                                    </button>

                                                    <button onClick={() => { handleVictoryWay(4.2); setIsPressWay(4.2); handleWin(3); }} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: '#363636',
                                                        color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 50, width: 160, fontSize: 15, marginLeft: 5, border: isPressWay === 4.2 ? '4px solid #0576ff' : 0
                                                    }}>
                                                        SPLIT DRAW
                                                    </button>

                                                    <button onClick={() => { handleVictoryWay(4.3); setIsPressWay(4.3); handleWin(3); }} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: '#363636',
                                                        color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 50, width: 160, fontSize: 15, marginLeft: 5, border: isPressWay === 4.3 ? '4px solid #0576ff' : 0
                                                    }}>
                                                        MAJORITY DRAW
                                                    </button>
                                                </>
                                                : null}
                                        </div>

                                        <div style={{ // way NO CONTEST
                                            flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 10, width: '100%',
                                            justifyContent: 'center', alignItems: 'center'
                                        }}>
                                            <button onClick={() => {
                                                handleVictoryWay(5); setIsPressWay(5); handleWin(4); //win: 4 = no contest
                                            }} style={{
                                                padding: '10px 20px', border: 'none', backgroundColor: '#363636',
                                                color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                height: 60, width: 200, fontSize: 20, border: isPressWay === 5 ? '4px solid #0576ff' : 0
                                            }}>
                                                NO CONTEST
                                            </button>
                                        </div>

                                        <div style={{ // way AUTRES
                                            flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 10, width: '100%',
                                            justifyContent: 'center', alignItems: 'center'
                                        }}>

                                            <button onClick={() => { handleVictoryWay(7); setIsPressWay(7) }} style={{
                                                padding: '10px 20px', border: 'none', backgroundColor: '#363636',
                                                color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                height: 50, width: 180, fontSize: 15, marginLeft: 5, border: isPressWay === 7 ? '4px solid #0576ff' : 0
                                            }}>
                                                DISQUALIFICATION
                                            </button>

                                            <button onClick={() => { handleVictoryWay(8); setIsPressWay(8) }} style={{
                                                padding: '10px 20px', border: 'none', backgroundColor: '#363636',
                                                color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                height: 50, width: 180, fontSize: 15, marginLeft: 5, border: isPressWay === 8 ? '4px solid #0576ff' : 0
                                            }}>
                                                FORFEIT
                                            </button>

                                            <button onClick={() => { handleVictoryWay(9); setIsPressWay(9); handleWin(3); }} style={{
                                                padding: '10px 20px', border: 'none', backgroundColor: '#363636',
                                                color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                height: 50, width: 180, fontSize: 15, marginLeft: 5, border: isPressWay === 9 ? '4px solid #0576ff' : 0
                                            }}>
                                                TECHNICAL DRAW
                                            </button>

                                            <button onClick={() => { handleVictoryWay(10); setIsPressWay(10) }} style={{
                                                padding: '10px 20px', border: 'none', backgroundColor: '#363636',
                                                color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                height: 50, width: 180, fontSize: 15, marginLeft: 5, border: isPressWay === 10 ? '4px solid #0576ff' : 0
                                            }}>
                                                TECHNICAL DECISION
                                            </button>
                                        </div>


                                    </div>

                                    <div style={{ // ROUND
                                        width: '20%', flexDirection: 'column', display: 'flex', alignItems: 'center', backgroundColor: '#dbdbdb'
                                    }}>

                                        <button onClick={() => { handleRound(1.5); setStateRound(1.5); setElapsedTimeEndTime1(0); setIsRunningEndTime1(!isRunningEndTime1); }} style={{// FIN ROUND 1
                                            padding: '10px 20px', border: 'none', backgroundColor: stateRound === 1 ? '#0576ff' : stateRound >= 1.5 ? 'grey' : 'white',
                                            color: 'black', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                            height: 60, width: 200, fontSize: 20
                                        }}>
                                            END ROUND 1
                                        </button>


                                        <button onClick={() => { handleRound(2); setStateRound(2); setIsRunningEndTime1(!isRunningEndTime1) }} style={{  // DÉBUT ROUND 2
                                            padding: '10px 20px', border: 'none', backgroundColor: stateRound === 1.5 ? '#0576ff' : stateRound >= 2 ? 'grey' : 'white',
                                            color: 'black', borderRadius: 100, marginTop: '30px', cursor: 'pointer',
                                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                            height: 60, width: 200, fontSize: 20
                                        }}>
                                            START ROUND 2
                                        </button>


                                        <button onClick={() => { handleRound(2.5); setStateRound(2.5); setElapsedTimeEndTime1(0); setIsRunningEndTime1(!isRunningEndTime1); }} style={{// FIN ROUND 2
                                            padding: '10px 20px', border: 'none', backgroundColor: stateRound === 2 ? '#0576ff' : stateRound >= 2.5 ? 'grey' : 'white',
                                            color: 'black', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                            height: 60, width: 200, fontSize: 20
                                        }}>
                                            END ROUND 2
                                        </button>


                                        <button onClick={() => { handleRound(3); setStateRound(3); setIsRunningEndTime1(!isRunningEndTime1) }} style={{ // DÉBUT ROUND 3
                                            padding: '10px 20px', border: 'none', backgroundColor: stateRound === 2.5 ? '#0576ff' : stateRound >= 3 ? 'grey' : 'white',
                                            color: 'black', borderRadius: 100, marginTop: '30px', cursor: 'pointer',
                                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                            height: 60, width: 200, fontSize: 20
                                        }}>
                                            START ROUND 3
                                        </button>


                                        <button onClick={() => {
                                            handleRound(3.5); setStateRound(3.5);

                                            if (combatData.numberOfRound === '5') {
                                                setElapsedTimeEndTime1(0); setIsRunningEndTime1(!isRunningEndTime1);
                                            }

                                            if (combatData.numberOfRound === '3') {
                                                setStateNumber(3);
                                                state(3);
                                                setIsRunningEndTime1(!isRunningEndTime1);
                                            }
                                        }} style={{ // FIN ROUND 3
                                            padding: '10px 20px', border: 'none', backgroundColor: stateRound === 3 ? '#0576ff' : stateRound >= 3.5 ? 'grey' : 'white',
                                            color: 'black', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                            height: 60, width: 200, fontSize: 20
                                        }}>
                                            END ROUND 3
                                        </button>

                                        {combatData.numberOfRound === '5' ?
                                            <button onClick={() => { handleRound(4); setStateRound(4); setIsRunningEndTime1(!isRunningEndTime1) }} style={{ // DÉBUT ROUND 4
                                                padding: '10px 20px', border: 'none', backgroundColor: stateRound === 3.5 ? '#0576ff' : stateRound >= 4 ? 'grey' : 'white',
                                                color: 'black', borderRadius: 100, marginTop: '30px', cursor: 'pointer',
                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                height: 60, width: 200, fontSize: 20
                                            }}>
                                                START ROUND 4
                                            </button> : null}

                                        {combatData.numberOfRound === '5' ?
                                            <button onClick={() => { handleRound(4.5); setStateRound(4.5); setElapsedTimeEndTime1(0); setIsRunningEndTime1(!isRunningEndTime1); }} style={{ // FIN ROUND 4
                                                padding: '10px 20px', border: 'none', backgroundColor: stateRound === 4 ? '#0576ff' : stateRound >= 4.5 ? 'grey' : 'white',
                                                color: 'black', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                height: 60, width: 200, fontSize: 20
                                            }}>
                                                END ROUND 4
                                            </button> : null}

                                        {combatData.numberOfRound === '5' ?
                                            <button onClick={() => { handleRound(5); setStateRound(5); setIsRunningEndTime1(!isRunningEndTime1) }} style={{ // DÉBUT ROUND 5
                                                padding: '10px 20px', border: 'none', backgroundColor: stateRound === 4.5 ? '#0576ff' : stateRound >= 5 ? 'grey' : 'white',
                                                color: 'black', borderRadius: 100, marginTop: '30px', cursor: 'pointer',
                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                height: 60, width: 200, fontSize: 20
                                            }}>
                                                START ROUND 5
                                            </button> : null}

                                        {combatData.numberOfRound === '5' ?
                                            <button onClick={() => { state(3); setStateNumber(3); handleRound(5.5); setStateRound(5.5); setIsRunningEndTime1(!isRunningEndTime1); }} style={{ // FIN ROUND 5
                                                padding: '10px 20px', border: 'none', backgroundColor: stateRound === 5 ? '#0576ff' : stateRound >= 5.5 ? 'grey' : 'white',
                                                color: 'black', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                height: 60, width: 200, fontSize: 20
                                            }}>
                                                END ROUND 5
                                            </button> : null}

                                    </div>

                                </div>
                            )}

                        </div>


                        <form onSubmit={handleEndTime} style={{
                            width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center',
                            marginTop: 10, backgroundColor: 'black', justifyContent: 'center'
                        }}>

                            <div style={{
                                flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px',
                            }}>
                                <h1 style={{ color: 'white' }}>{formatTime(elapsedTimeEndTime1)}</h1>
                            </div>

                            <div style={{
                                width: '30%', marginBottom: '10px', flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center'
                            }}>
                                <label style={{ color: 'white', fontWeight: 'bold' }}>END TIME</label>

                                <input
                                    type="text"
                                    value={endTimeMinute}
                                    onChange={(e) => setEndTimeMinute(e.target.value)}
                                    style={{
                                        width: '4%', padding: '10px 7px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 20, fontWeight: 'bold', marginLeft: 3
                                    }}
                                />

                                <label style={{ color: 'white', fontWeight: 'bold', marginLeft: 3 }}>:</label>

                                <input
                                    type="text"
                                    value={endTimeSeconde}
                                    onChange={(e) => setEndTimeSeconde(e.target.value)}

                                    style={{
                                        width: '8%', padding: '10px 7px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 20, fontWeight: 'bold', marginLeft: 3
                                    }}
                                />

                                <button
                                    type="submit"
                                    style={{
                                        padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
                                        color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder', marginLeft: 10
                                    }}
                                >
                                    {t("Submit")}
                                </button>

                            </div>

                        </form>



                    </div>

                    : null}


                {formulaireVisible === 2 ? // && (adminRole === 'all' || adminRole === 'adminStatsStrike' || adminRole === 'adminStatsOthers') --> voir explication écrite dans dossier FightApp

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10 }}>
                        <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%', }}>

                            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: 'black' }}>
                                <h2 style={{ color: 'white' }}>{combatData.id}</h2>
                            </div>

                            {dataReady && (

                                <div style={{ width: '100%', backgroundColor: '#ebebeb', }}>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10, backgroundColor: '#dedede', }}>
                                        <div style={{
                                            width: '100%', marginTop: 10, marginBottom: '10px', flexDirection: 'row', justifyContent: 'space-evenly', display: 'flex', alignItems: 'center'
                                        }}>
                                            <div style={{ // fighter1
                                                flexDirection: 'column', display: 'flex', alignItems: 'center'
                                            }}>
                                                <img src={combattant1Data.photo} alt="Logo" style={{ height: 150, width: 220 }} />
                                                <label style={{ fontSize: 25, fontWeight: 'bold', marginTop: 10, color: '#f03329' }}>{combattant1Data.lastName}</label>
                                            </div>

                                            {adminRole === 'all' || adminRole === 'adminStatsStrike' ?

                                                <div style={{ // FRAPPES 
                                                    flexDirection: 'column', display: 'flex', alignItems: 'center'
                                                }}>
                                                    <div style={{
                                                        flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 40,
                                                    }}>

                                                        <div style={{
                                                            flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px',
                                                        }}>
                                                            <label style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>{strikeSign1}</label>
                                                            <button onClick={() => setStrikeSign1(prevState => prevState + 1)} style={{
                                                                padding: '10px 20px', border: 'none', marginTop: '10px', backgroundColor: '#f03329',
                                                                color: 'white', borderRadius: 100, cursor: 'pointer',
                                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                                height: 90, width: 180, fontSize: 15,
                                                            }}>
                                                                SIGNIFICANT STRIKES
                                                            </button>
                                                        </div>


                                                        <div style={{
                                                            flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px',
                                                        }}>
                                                            <label style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>{strikeNormal1}</label>
                                                            <button onClick={() => setStrikeNormal1(prevState => prevState + 1)} style={{
                                                                padding: '10px 20px', border: 'none', backgroundColor: '#f03329',
                                                                color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                                height: 90, width: 180, fontSize: 15, marginLeft: 10,
                                                            }}>
                                                                NORMAL STRIKES
                                                            </button></div>

                                                        <div style={{
                                                            flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px',
                                                        }}>
                                                            <label style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>{strikeNormal2}</label>
                                                            <button onClick={() => setStrikeNormal2(prevState => prevState + 1)} style={{
                                                                padding: '10px 20px', border: 'none', backgroundColor: '#0569ff',
                                                                color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                                height: 90, width: 180, fontSize: 15, marginLeft: 30,
                                                            }}>
                                                                NORMAL STRIKES
                                                            </button></div>

                                                        <div style={{
                                                            flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px',
                                                        }}>
                                                            <label style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>{strikeSign2}</label>
                                                            <button onClick={() => setStrikeSign2(prevState => prevState + 1)} style={{
                                                                padding: '10px 20px', border: 'none', backgroundColor: '#0569ff',
                                                                color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                                height: 90, width: 180, fontSize: 15, marginLeft: 10,
                                                            }}>
                                                                SIGNIFICANT STRIKES
                                                            </button></div>

                                                    </div>

                                                </div>

                                                : null}

                                            <div style={{ // fighter 2
                                                flexDirection: 'column', display: 'flex', alignItems: 'center'
                                            }}>
                                                <img src={combattant2Data.photo} alt="Logo" style={{ height: 150, width: 220 }} />
                                                <label style={{ fontSize: 25, fontWeight: 'bold', marginTop: 10, color: '#0569ff' }}>{combattant2Data.lastName}</label>


                                            </div>


                                        </div>

                                        {adminRole === 'all' || adminRole === 'adminStatsStrike' ?
                                            <button
                                                onClick={handleSubmitStatsStrikes}
                                                style={{
                                                    padding: '10px 20px', border: 'none', backgroundColor: 'black',
                                                    color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                                                    outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder', marginBottom: '10px',
                                                }}
                                            >
                                                End of round - submit
                                            </button>

                                            : null}
                                    </div>

                                    {adminRole === 'all' || adminRole === 'adminStatsOthers' ?
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10 }}>

                                            <div style={{// KNOCKDOWNS
                                                flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 40,
                                            }}>

                                                <div style={{ // KNOCKDOWNS
                                                    flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px',
                                                }}>
                                                    <label style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>{knockdowns1}</label>
                                                    <button onClick={() => setKnockdowns1(prevState => prevState + 1)} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: '#f03329',
                                                        color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 90, width: 180, fontSize: 15,
                                                    }}>
                                                        KNOCKDOWNS
                                                    </button> </div>

                                                <div style={{ // KNOCKDOWNS
                                                    flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px',
                                                }}>
                                                    <label style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>{knockdowns2}</label>

                                                    <button onClick={() => setKnockdowns2(prevState => prevState + 1)} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: '#0569ff',
                                                        color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 90, width: 180, fontSize: 15,
                                                    }}>
                                                        KNOCKDOWNS
                                                    </button></div>

                                            </div>

                                            <div style={{ // TAKEDOWNS
                                                flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 20,
                                            }}>

                                                <div style={{
                                                    flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px',
                                                }}>
                                                    <label style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>{takedowns1}</label>
                                                    <button onClick={() => setTakedowns1(prevState => prevState + 1)} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: '#f03329',
                                                        color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 90, width: 180, fontSize: 15,
                                                    }}>
                                                        TAKEDOWNS
                                                    </button></div>


                                                <div style={{
                                                    flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px',
                                                }}>
                                                    <label style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>{takedowns2}</label>
                                                    <button onClick={() => setTakedowns2(prevState => prevState + 1)} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: '#0569ff',
                                                        color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 90, width: 180, fontSize: 15,
                                                    }}>
                                                        TAKEDOWNS
                                                    </button></div>

                                            </div>

                                            <div style={{ //  TENTATIVE DE SOUMISSION
                                                flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 20,
                                            }}>
                                                <div style={{
                                                    flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px',
                                                }}>
                                                    <label style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>{subTry1}</label>
                                                    <button onClick={() => setSubTry1(prevState => prevState + 1)} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: '#f03329',
                                                        color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 90, width: 180, fontSize: 15,
                                                    }}>
                                                        SUBMISSION TRIES
                                                    </button></div>


                                                <div style={{
                                                    flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px',
                                                }}>
                                                    <label style={{ fontSize: 20, fontWeight: 'bold', color: 'black' }}>{subTry2}</label>
                                                    <button onClick={() => setSubTry2(prevState => prevState + 1)} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: '#0569ff',
                                                        color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 90, width: 180, fontSize: 15,
                                                    }}>
                                                        SUBMISSION TRIES
                                                    </button></div>

                                            </div>

                                            <div style={{ // TEMPS DE CONTROLE
                                                flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 20,
                                            }}>

                                                <div style={{
                                                    flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px',
                                                }}>
                                                    <h2>Fighter 1</h2>
                                                    <button onClick={() => setIsRunningControl1(!isRunningControl1)} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: '#f03329',
                                                        color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 90, width: 180, fontSize: 15,
                                                    }}>
                                                        {isRunningControl1 ? 'Arrêter' : 'Démarrer'}
                                                    </button>
                                                    <h1>{formatTime(elapsedTimeControl1)}</h1>
                                                </div>

                                                <div style={{
                                                    flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px',
                                                }}>
                                                    <h2>Fighter 2</h2>
                                                    <button onClick={() => setIsRunningControl2(!isRunningControl2)} style={{
                                                        padding: '10px 20px', border: 'none', backgroundColor: '#0569ff',
                                                        color: 'white', borderRadius: 100, marginTop: '10px', cursor: 'pointer',
                                                        outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                                                        height: 90, width: 180, fontSize: 15,
                                                    }}>
                                                        {isRunningControl2 ? 'Arrêter' : 'Démarrer'}
                                                    </button>

                                                    <h1>{formatTime(elapsedTimeControl2)}</h1>
                                                </div>


                                            </div>


                                            <button
                                                onClick={handleSubmitStatsOthers}
                                                style={{
                                                    padding: '10px 20px', border: 'none', backgroundColor: 'black',
                                                    color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                                                    outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder', marginBottom: '10px',
                                                }}
                                            >
                                                End of the round - Submit
                                            </button>
                                        </div>
                                        : null}

                                </div>
                            )}
                        </div>
                    </div>

                    : null}
            </div>
        </div >

    );
}